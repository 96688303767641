import SxDef from '@core/types/SxDef';
import { expandDisplayFlex } from '@core/utils/expandShorthandCss';

const useSx = (): SxDef => ({
  root: {
    ...expandDisplayFlex({ d: 'column' }),
    flex: 1,
    gap: 'inherit',
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  input: (theme) => ({
    marginBottom: '1rem',
    ...(theme.palette.mode === 'dark' ? { border: 'none' } : {}),
    '& .MuiInputBase-input': {
      paddingLeft: '9px',
    },
    '.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
  }),
  templateDropdown: {
    marginBottom: '1rem',
    '& .MuiInputLabel-root': {
      top: '-7px',
    },
    '& .MuiOutlinedInput-root': {
      paddingY: 0,
    },
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: {
      md: 'divider',
      xs: 'transparent',
    },
    marginBottom: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: '1rem',
    alignItems: 'center',
    '.MuiTabs-root': {
      marginRight: 'auto',
    },
    '.MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& .Mui-selected': {
      color: (theme) =>
        `${
          theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[300]
        } !important`,
    },
    '.tab': {
      color: (theme) =>
        theme.palette.mode === 'dark' ? theme.palette.shade?.[900] : theme.palette.shade?.[300],
      ':hover': { cursor: 'default' },
    },
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& .MuiTextField-root': {
      marginBottom: 0,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    bottom: 0,
    right: 0,
    width: '100%',
    background: (theme) => theme.palette.shade?.[200],
    padding: {
      xs: '24px',
      md: 0,
    },
    position: {
      xs: 'absolute',
      md: 'unset',
    },
  },
  footerButtons: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '15px 10px',
  },
});

export default useSx;
