import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Input, InputAdornment, Tooltip, Typography } from '@mui/material';
import If from '@components/If';
import { GetFoldersResponse, TemplateFile, TemplateFolder } from '@core/types';
import useSx from './sx';

dayjs.extend(utc);

interface FolderViewProps {
  itemArray: GetFoldersResponse['root'];
  initialPath?: TemplateFolder['folderHierarchyItemId'][];
  onTemplateClick?: (template: TemplateFile) => void;
  smallView?: boolean;
  enableLocalSearch?: boolean;
  onFolderNavigation?: (folder: TemplateFolder, folderPath: number[]) => void;
  onEditFolder?: (newTemplateName: string, item: TemplateFolder, folderPath: number[]) => void;
}

export default function FolderView({
  itemArray,
  onTemplateClick = () => {},
  onFolderNavigation = () => {},
  initialPath = [],
  smallView = false,
  enableLocalSearch = false,
  onEditFolder,
}: FolderViewProps) {
  const ROOT_BREADCRUMB: TemplateFolder = {
    folderHierarchyItemId: 0,
    isFile: false,
    name: 'Plantillas',
    ownerUserUid: true,
    organizationWide: true,
    modifiedDate: '',
    subItems: itemArray,
  };
  const [display, setDisplay] = useState<TemplateFolder['subItems']>(itemArray);
  const [breadcrumbs, setBreadcrumbs] = useState<TemplateFolder[]>([ROOT_BREADCRUMB]);
  const [localFilter, setLocalFilter] = useState<string>('');
  const sx = useSx();

  function navigateFolder(folder: TemplateFolder) {
    const newBreadcrumbs = [...breadcrumbs, folder];

    const folderPath = newBreadcrumbs
      .filter((s) => s.folderHierarchyItemId !== 0)
      .map((breadcrumb) => breadcrumb.folderHierarchyItemId);
    setBreadcrumbs([...breadcrumbs, folder]);
    setDisplay(folder.subItems);

    onFolderNavigation(folder, folderPath);
  }

  function breadcrumbClick(folder: TemplateFolder, index: number) {
    const newBreadcrumbs = breadcrumbs.splice(0, index + 1);

    setBreadcrumbs(newBreadcrumbs);
    setDisplay(folder.subItems);

    const folderPath = newBreadcrumbs
      .filter((s) => s.folderHierarchyItemId !== 0)
      .map((breadcrumb) => breadcrumb.folderHierarchyItemId);
    onFolderNavigation(folder, folderPath);
  }

  function handleLocalFilter(element: TemplateFile | TemplateFolder) {
    return !element.name.toLocaleLowerCase().includes(localFilter.toLocaleLowerCase());
  }
  const clickTimeout = useRef<number | undefined>(undefined);

  const handleTemplateClick = (item: TemplateFolder | TemplateFile) => {
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
    }

    clickTimeout.current = window.setTimeout(() => {
      const selectedFunc = item.isFile ? onTemplateClick : navigateFolder;
      selectedFunc(item as never);
      clickTimeout.current = undefined;
    }, 250);
  };

  const [selectedItem, setSelectedItem] = useState<TemplateFolder | null>(null);
  const [newTemplateName, setNewTemplateName] = useState('');

  const handleDoubleClick = (item: TemplateFolder | TemplateFile) => {
    window.clearTimeout(clickTimeout.current);
    clickTimeout.current = undefined;

    if (item.isFile) return;

    setSelectedItem(item as TemplateFolder);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (selectedItem && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [selectedItem]);

  const filteredComponentArray = Object.keys(display).reduce((acc, curr) => {
    if (handleLocalFilter(display[curr]) && enableLocalSearch) return acc;

    return [
      ...acc,
      <Box
        sx={sx.rowContent}
        key={curr}
        onDoubleClick={() => {
          handleDoubleClick(display[curr]);
        }}
        onClick={() => {
          handleTemplateClick(display[curr]);
        }}
      >
        <If condition={display[curr].isFile} fallback={<FolderIcon sx={sx.coloredIcon} />}>
          <InsertDriveFileIcon sx={sx.Icon} />
        </If>
        <If
          condition={
            !display[curr].isFile &&
            display[curr].folderHierarchyItemId === selectedItem?.folderHierarchyItemId
          }
          fallback={<Typography sx={sx.templateName}>{display[curr].name}</Typography>}
        >
          <Input
            inputRef={inputRef}
            value={newTemplateName}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setNewTemplateName(e.target.value);
            }}
            onBlur={() => {
              const folderPath = breadcrumbs
                .filter((s) => s.folderHierarchyItemId !== 0)
                .map((breadcrumb) => breadcrumb.folderHierarchyItemId);

              if (!!selectedItem && newTemplateName.length > 0) {
                onEditFolder?.(newTemplateName, selectedItem, folderPath);
              }
              setNewTemplateName('');
              setSelectedItem(null);
              inputRef.current = null;
            }}
            sx={sx.templateName}
            placeholder="Nuevo nombre"
          />
        </If>
        <If condition={display[curr].organizationWide}>
          <Tooltip title="Organizacíon">
            <ApartmentIcon sx={sx.organizationIcon} />
          </Tooltip>
        </If>
        <Typography sx={sx.templateDate}>
          {dayjs.utc(display[curr].modifiedDate).local().format('DD/MM/YYYY h:mm A')}
        </Typography>
      </Box>,
    ];
  }, [] as JSX.Element[]);

  useEffect(() => {
    // Next block of code is used to navigate to the folder indicated on the initial path
    let INITIAL_FOLDER = itemArray;
    let INITIAL_BREADCRUMBS = [ROOT_BREADCRUMB];

    const folderIds = [...initialPath];

    while (folderIds.length > 0) {
      const folderId = folderIds.shift();
      const foundFolder = INITIAL_FOLDER[`folder-${folderId}`];

      if (foundFolder && foundFolder.subItems) {
        INITIAL_FOLDER = foundFolder.subItems;
        INITIAL_BREADCRUMBS = [...INITIAL_BREADCRUMBS, foundFolder];
      } else {
        break;
      }
    }

    setBreadcrumbs(INITIAL_BREADCRUMBS);
    setDisplay(INITIAL_FOLDER);
  }, [itemArray]);

  return (
    <Box sx={sx.card}>
      <Box sx={sx.commandsContainer}>
        <Box sx={sx.breadcrumbsContainer}>
          {breadcrumbs.map((folder, index) => {
            return (
              <If
                condition={index !== breadcrumbs.length - 1}
                key={folder.name}
                fallback={<Typography key={folder.name}>{folder.name}</Typography>}
              >
                <Typography
                  key={folder.name}
                  onClick={() => breadcrumbClick(folder, index)}
                  sx={sx.clickableBreadcrumb}
                >
                  {folder.name}
                </Typography>
                <Typography>{`>`}</Typography>
              </If>
            );
          })}
        </Box>
        <If condition={enableLocalSearch}>
          <Input
            placeholder="Buscar"
            onChange={(e) => {
              setLocalFilter(e.target.value);
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            sx={sx.searchInput}
          />
        </If>
      </Box>
      <Box sx={smallView ? sx.foldersViewSmall : sx.foldersView}>
        {filteredComponentArray.length ? (
          filteredComponentArray
        ) : (
          <Box sx={sx.rowContent}>
            <Typography sx={sx.templateName}>
              No se ha encontrado ningún archivo con ese nombre dentro de esta carpeta
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
