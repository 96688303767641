import { Theme } from '@mui/material';
import SxDef from '@core/types/SxDef';

const lightBorders = (theme: Theme) => ({
  borderTop: `1px solid ${theme.palette.grey[800]}`,
  borderLeft: `1px solid ${theme.palette.grey[800]}`,
  borderRight: `1px solid ${theme.palette.grey[800]}`,
});

const useSx = (): SxDef => ({
  root: (theme) => ({
    '& > div': { height: '750px', borderRadius: '20px', overflow: 'hidden' },
    '.ms-FocusZone': {
      borderColor: 'divider',
      borderBottomWidth: '1px',
      borderBottomColor: 'divider',
      borderBottomStyle: 'solid',
      ...(theme.palette.mode === 'light' ? lightBorders(theme) : {}),
    },
    '.rooster': {
      borderTop: `1px solid ${theme.palette.grey[800]}`,
      border: theme.palette.mode === 'light' ? `1px solid ${theme.palette.grey[800]}` : ``,
      background: `inherit !important`,
      width: '100%',
      height: 'calc(100% - 44px)',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#777777',
      },

      '::-webkit-scrollbar-thumb': {
        background: '#555555',
      },

      '::-webkit-scrollbar-thumb:hover': {
        background: '#444444',
      },
      outline: 'none',
      padding: '1rem',
    },
    '.error-text': {
      color: theme.palette.error.main,
      fontFamily:
        'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
      fontWeight: 400,
      fontSize: '0.65625rem',
      lineHeight: '1.66',
      textAlign: 'left',
      marginTop: '3px',
      marginRigt: '14px',
      marginBottom: 0,
      marginLeft: '14px',
    },
  }),
});

export default useSx;
