const createFooterDiv = () => {
  const divFooter = document.createElement('div');
  divFooter.id = 'footer-editor';
  divFooter.style.width = '100%';
  divFooter.style.position = 'absolute';
  divFooter.style.left = '0%';
  divFooter.style.bottom = '0%';
  divFooter.style.lineHeight = '1.2em';
  return divFooter;
};

export default createFooterDiv;
