import React from 'react';
import useProfile from '@core/hooks/useProfile';
import { Gender } from '@core/types/Gender';
import useUpdateUser from '../../hooks/useUpdateUser';

const useFormState = () => {
  const { userUid, userProfileInfo: user, organization } = useProfile();
  const { updateUser, isLoading } = useUpdateUser(userUid, organization?.organizationId);

  const initialValues = {
    givenName: user?.givenName ?? '',
    surname: user?.surname ?? '',
    gender: user?.gender ?? ('' as Gender),
    emailAddress: user?.emailAddress ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    birthDate: user?.birthDate ?? undefined,
  };

  return {
    updateUser,
    user,
    initialValues,
    isLoading,
  };
};

export default useFormState;
