import React, { RefObject, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { Editor } from 'roosterjs-editor-core';
import sanitizeHtml from 'sanitize-html';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ActionButton from '@components/Button/ActionButton';
import If from '@components/If';
import useProfile from '@core/hooks/useProfile';
import FormDialogProps, { InitialValues } from './FormDialogProps';
import PreviewTextEditor from './PreviewTextEditor';
import TemplateTagDropdown from './TemplateTagDropdown';
import useSx from './sx';

const FormDialog = ({
  onDelete,
  onAddNew,
  onSave,
  initialValues,
  canUserEditTemplate,
  title,
}: FormDialogProps) => {
  const sx = useSx();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const editor = useRef<Editor>(null);
  const navigate = useNavigate();
  const { hasAdminPermissions } = useProfile();
  const formVersion = !!initialValues.content ? 'update' : 'add';

  const defaultSubmit = () => {};

  async function validateEditorContent(
    editorProp: RefObject<Editor>,
    validateForm: (values?: any) => Promise<FormikErrors<InitialValues>>,
    setErrors: (errors: FormikErrors<InitialValues>) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => Promise<void | FormikErrors<InitialValues>>,
  ) {
    const content = editorProp.current?.getContent();
    if (content !== undefined) {
      const sanitizedHTML = sanitizeHtml(content, {
        allowedTags: [],
      });

      const newErrors = await validateForm();

      if (sanitizedHTML.length < 1) {
        setErrors({ ...newErrors, content: 'Por favor introduzca el contenido' });
        throw new Error('Invalid content');
      }

      await setFieldValue('content', content);
      return content;
    }
  }

  useEffect(() => {
    const button = document.getElementById('left-drawer-close-button');
    if (button) {
      button.click();
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      onSubmit={onSave || defaultSubmit}
    >
      {({ values, handleSubmit, setFieldValue, errors, resetForm, setErrors, validateForm }) => (
        <Box onSubmit={handleSubmit} sx={sx.root} className="form" component="form">
          <Box sx={sx.tabsContainer}>
            <Tabs value={'no-value'} onChange={() => {}}>
              <Tab className="tab" value="no-value" label={title} />
            </Tabs>
            <If condition={hasAdminPermissions && canUserEditTemplate}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.organizationWide}
                    onChange={(e) => {
                      setFieldValue('organizationWide', e.target.checked);
                    }}
                  />
                }
                label="Mostrar a toda la organización"
              />
            </If>
            <If condition={!!onDelete && canUserEditTemplate}>
              <ActionButton
                text="Eliminar"
                variant="contained"
                color="error"
                onClick={onDelete}
                icon={<DeleteOutlineOutlinedIcon />}
              />
            </If>
            <If condition={canUserEditTemplate}>
              <ActionButton
                text="Cancelar"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  resetForm();
                  navigate('/templates');
                }}
              />
            </If>
            <If condition={formVersion === 'update'}>
              <ActionButton
                text="Guardar como nuevo"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  validateEditorContent(editor, validateForm, setErrors, setFieldValue)
                    .then((content) => {
                      if (content) onAddNew?.({ ...values, content });
                    })
                    .catch(console.error);
                }}
              />
            </If>
            <ActionButton
              text="Guardar"
              variant="contained"
              color="primary"
              onClick={() => {
                validateEditorContent(editor, validateForm, setErrors, setFieldValue)
                  .then(() => {
                    formikRef.current?.handleSubmit();
                  })
                  .catch(console.error);
              }}
            />
          </Box>
          <TemplateTagDropdown editor={editor} />
          <PreviewTextEditor
            initialContent={values.content}
            textEditorRef={editor}
            error={!!errors.content}
            helperText={errors.content}
          />
          <Box sx={sx.footerButtons}></Box>
        </Box>
      )}
    </Formik>
  );
};

export default FormDialog;
