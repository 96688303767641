function insertVariable(variableName: string) {
  return `<span class="${variableName}">{{${variableName}}}</span>`;
}

export const initialTemplate = `
<p style="margin: 0; line-height: 1.3;">
    <b>NOMBRE:</b> ${insertVariable('PatientFirstName')} ${insertVariable(
  'PatientLastName',
)}, ${insertVariable('PatientAge')}
</p>
<p style="margin: 0; line-height: 1.3;">
    <b>ESTUDIO:</b> ${insertVariable(
      'StudyName',
    )}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>FECHA:</b> ${insertVariable(
  'StudyDate',
)}
</p>
<p style="margin: 0; line-height: 1.3;">
    DR. ${insertVariable('Doctor')}
</p>
<p style="margin: 0; line-height: 1.3;">&nbsp;</p>
`;
