import React, { useMemo } from 'react';
import { RibbonButton, RibbonButtonDropDown, getButtons } from 'roosterjs-react';
import { OrganizationTemplateModel } from '@core/types/OrganizationTemplate';
import { isAllowedFont } from '@core/utils/fonts';
import { ButtonFactoryProps } from 'app/features/reports/add/buttons/buttonRegistryFactory';
import extractElementFromArray from 'app/features/reports/add/utils/extractElementFromArray';
import { getRegisteredButtons } from '../buttons/buttonRegistryFactory';
import '../buttons/registry';

const filterFonts = (button: RibbonButton<string>) => {
  const fonts = (
    button.dropDownMenu?.items ? Object.entries(button.dropDownMenu.items) : []
  ).filter((fontEntry) => isAllowedFont(fontEntry[1]));

  if (fonts.length > 0) {
    button.dropDownMenu!.items = Object.fromEntries(fonts);
  }
};

const handleDropdownButtonStyles = (button: RibbonButton<string> | undefined) => {
  if (button?.dropDownMenu) {
    button.dropDownMenu.commandBarSubMenuProperties =
      button.dropDownMenu.commandBarSubMenuProperties || {};
    button.dropDownMenu.commandBarSubMenuProperties.className = 'dropdown-menu';
  }
};

const useGetFilteredButtons = (themeMode: string, templates: OrganizationTemplateModel[]) => {
  const buttons = getButtons() as unknown as RibbonButton<string>[];

  const filteredButtons = useMemo(() => {
    const ribbonButtons: RibbonButton<string>[] = buttons.map((button) => {
      const newButton = { ...button };
      newButton.commandBarProperties = newButton.commandBarProperties || {};
      newButton.commandBarProperties!.className = 'command-button';
      if (newButton.key === 'buttonNameFont') {
        filterFonts(button);
      }

      handleDropdownButtonStyles(newButton);

      return newButton;
    });

    const buttonProps: ButtonFactoryProps = {
      themeMode,
      userInfo: {},
      studyInfo: {},
      templates,
    };

    const buttonsToRemove = [
      'buttonNameQuote',
      'buttonNameInsertLink',
      'buttonNameRemoveLink',
      'buttonNameSubscript',
      'buttonNameStrikethrough',
      'buttonNameCode',
      'buttonNameLtr',
      'buttonNameRtl',
      'buttonNameInsertTable',
    ];

    const filteredRibbonButtons = ribbonButtons.filter(
      (button) => !buttonsToRemove.includes(button.key),
    );

    const [customButtons, lineHeightButton] = extractElementFromArray(
      getRegisteredButtons(buttonProps),
      (elem) => elem.key === 'buttonNameSpacing',
    );

    const fontDownIndex =
      filteredRibbonButtons.findIndex((button) => button.key === 'buttonNameDecreaseFontSize') + 1;

    return [
      ...filteredRibbonButtons.slice(0, fontDownIndex),
      lineHeightButton,
      ...filteredRibbonButtons.slice(fontDownIndex),
      ...customButtons,
    ];
  }, [themeMode, buttons, templates]);

  return { filteredButtons };
};

export default useGetFilteredButtons;
